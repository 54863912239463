<template>
  <v-container fluid style="background-color: #f4f4f5 !important">
    <v-row class="mt-5" justify="center" align="center">
      <v-card width="820" elevation="2">
        <v-card-title class="text-small">
          <small><strong>Product setup</strong></small>
        </v-card-title>
        <v-card-subtitle>
          General product information lives here
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field label="Name *" v-model="get_supplier_product_setup.name" outlined dense
                @blur="update_supplier_product">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-autocomplete label="Type *" prepend-inner-icon="mdi-tooltip-text-outline"
                :items="get_supplier_product_type" item-value="id" item-text="name" outlined dense chips return-object
                @change="change_supplier_product_type(get_supplier_product_setup.supplier_product_type_id)
                  " v-model="get_supplier_product_setup.supplier_product_type_id">
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field v-if="showcode == 1" label="Code" v-model="get_supplier_product_setup.code" outlined dense
                @blur="update_supplier_product">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <label>Description</label>
              <wysiwyg v-model="get_supplier_product_setup.description" />
            </v-col>
            <v-btn @click="update_supplier_product">Update</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: { },
  props: [],
  data: () => ({
    f: {
      supplier_product_type_id: null,
      name: null,
      code: null,
      description: null,
    },
    showcode: 0,
  }),
  mounted() {
    this.$store.dispatch("supplier/fetch_supplier_product_type");
  },
  created() {
    this.showcode =
      this.get_supplier_product_setup.supplierproducttype.with_code;
  },
  computed: {
    ...mapGetters({
      get_supplier_setup: "supplier/get_supplier_setup",
      get_supplier_product_type: "supplier/get_supplier_product_type",
      get_supplier_product_setup: "supplier/get_supplier_product_setup",
    }),
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit(
        "auth/setMessage",
        { show: true, message: message },
        { root: 1 }
      );
    },
    async change_supplier_product_type(e) {
      this.showcode = e == null ? 0 : e.with_code
      let payload = {
        supplier_id: this.get_supplier_setup.id,
        name: this.get_supplier_product_setup.name,
        supplier_product_type_id: this.get_supplier_product_setup.supplier_product_type_id.id,
        code: e.with_code == 0 ? "" : this.get_supplier_product_setup.code,
        description: this.get_supplier_product_setup.description,
      }
      console.log(payload)
      await this.$axios.patch(`suppliers/products/change_products/${this.get_supplier_product_setup.id}`, payload)
        .then(({ data }) => {
          this.showSnackBar(data.message)
          if (data.response) {
            this.$store.dispatch("supplier/set_supplier_product", data.data.list)
            this.$store.dispatch("supplier/set_supplier_product_setup", data.data.update)
          }
        })
    },
    async update_supplier_product() {
      let payload = {
        supplier_id: this.get_supplier_setup.id,
        name: this.get_supplier_product_setup.name,
        supplier_product_type_id: this.get_supplier_product_setup.supplier_product_type_id.id ? this.get_supplier_product_setup.supplier_product_type_id.id : this.get_supplier_product_setup.supplier_product_type_id,
        code: this.get_supplier_product_setup.code,
        description: this.get_supplier_product_setup.description,
      }
      await this.$axios.patch(`suppliers/products/change_products/${this.get_supplier_product_setup.id}`, payload)
        .then(({ data }) => {
          this.showSnackBar(data.message)
          if (data.response) {
            this.$store.dispatch("supplier/set_supplier_product", data.data.list)
            this.$store.dispatch("supplier/set_supplier_product_setup", data.data.update)
          }
        });
    },
  },
  watch: {},
};
</script>
<style scoped lang="scss"></style>
