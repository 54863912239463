<template>
    <v-dialog :value="value" persistent max-width="60%" overlay-color="#f4f4f5" overlay-opacity="1">
        <v-btn fab @click="close_child()" class="dialog-close">
            <v-icon dark> mdi-arrow-left </v-icon>
        </v-btn>
        <v-card>
            <v-card-title>
                Rate periods - {{ get_rate_period_child.name }}
                <v-spacer />
                <v-icon @click="close_child()">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-subtitle>
                Create a rate period and enter the applicable rates
            </v-card-subtitle>
            <v-card-text>
                <v-text-field outlined v-model="a.rate_name" placeholder="Name *" label="Name *" dense>
                </v-text-field>
                <v-row>
                    <v-col cols="6">
                        <v-text-field type="date" dense outlined label="Start Date *" v-model="a.start_date">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="date" dense outlined label="End Date *" v-model="a.end_date">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
                <span>Enter rates for:</span>
                <v-row v-for="(item, index) in drates" :key="item.name">
                    <v-col cols="4">
                        <v-text-field v-model="item.day" dense outlined label="Day" readonly required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field v-model="item.net" type="number" dense outlined label="Net *"
                            @keyup="computerates(item, index)" required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field v-model="item.markup" type="number" dense outlined label="Markup % *"
                            @keyup="computerates(item, index)" required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field v-model="item.gross" type="number" dense outlined label="Gross *" readonly required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field v-model="item.commission" type="number" dense outlined label="Commission *" readonly
                            required>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn class="pl-5 pr-5" rounded outlined @click="close_child()">
                    <v-icon class="mr-2">
                        mdi-close
                    </v-icon>
                    Cancel
                </v-btn>
                <v-btn color="primary" class="pl-5 pr-5" rounded @click="save_child_data()">
                    <v-icon class="mr-2">
                        mdi-content-save
                    </v-icon>
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
    components: {},
    props: [
        'value'
    ],
    data: () => ({
        a: {
            rate_name: null,
            start_date: null,
            end_date: null
        },
        drates: [
            {
                day: "Monday",
                net: 0,
                markup: 0,
                gross: 0,
                commission: 0,
            },
            {
                day: "Tuesday",
                net: 0,
                markup: 0,
                gross: 0,
                commission: 0,
            },
            {
                day: "Wednesday",
                net: 0,
                markup: 0,
                gross: 0,
                commission: 0,
            },
            {
                day: "Thursday",
                net: 0,
                markup: 0,
                gross: 0,
                commission: 0,
            },
            {
                day: "Friday",
                net: 0,
                markup: 0,
                gross: 0,
                commission: 0,
            },
            {
                day: "Saturday",
                net: 0,
                markup: 0,
                gross: 0,
                commission: 0,
            },
            {
                day: "Sunday",
                net: 0,
                markup: 0,
                gross: 0,
                commission: 0,
            },
        ],
    }),
    mounted() {

    },
    created() {

    },
    computed: {
        ...mapGetters({
            get_rate_period_child: 'supplier/get_rate_period_child'
        })
    },
    methods: {
        close_child() {
            this.$emit('close_child')
        },
        computerates(item, index) {
            const a = item.net * (item.markup / 100);
            const gross = Number(item.net) + Number(a);
            this.drates[index].gross = Number(gross);
            this.drates[index].commission = Number(item.markup / gross);
        },
        async save_child_data() {
            let tp = {
                name: this.a.rate_name,
                start_date: this.a.start_date,
                end_date: this.a.end_date,
                rates: this.drates,
                soc_id: this.get_rate_period_child.id
            }
            await this.$store.dispatch('supplier/add_option_children_rate', tp)
            this.$emit('close_deep')
        }
    },
    watch: {

    }
}
</script>
<style scoped>
.dialog-close {
    margin-left: -75px;
    position: absolute;
}
</style>
