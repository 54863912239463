<template>
  <v-container fluid style="background-color: #f4f4f5 !important">
    <v-row class="mt-5" justify="center" align="center">
      <v-card width="820" elevation="2">
        <v-card-title class="text-small">
          <small><strong>Times</strong></small>
          <v-spacer />
          <v-btn
            color="#588BAD"
            rounded
            small
            class="pl-10 pr-10"
            dark
            style="texttransform: none !important; color: white"
            @click="create_time_checkein()"
          >
            Add Time
          </v-btn>
        </v-card-title>
        <v-card-subtitle> Start & End times </v-card-subtitle>

        <v-card-text>
          <!-- {{get_supplier_amenities}} -->
          <v-list-item
            two-line
            v-for="(item, index) in get_supplier_product_time"
            :key="index"
            style="border-top: 1px solid gray; border-bottom: 1px solid gray"
            class="mt-2"
          >
            <v-list-item-content>
              <v-row>
                <v-col cols="6">
                  <v-list-item-title>
                    <v-input :error-messages="[item.check_in]" error disabled>
                      Check in time
                    </v-input>
                  </v-list-item-title></v-col
                >
                <v-col cols="6">
                  <v-list-item-title>
                    <v-input :error-messages="[item.check_out]" error disabled>
                      Check out time
                    </v-input>
                  </v-list-item-title></v-col
                >
              </v-row>
            </v-list-item-content>
            <v-list-item-action>
              <v-row>
                <v-btn icon>
                  <v-icon
                    @click="archive_supplier_product_time(item)"
                    color="grey lighten-1"
                    >mdi-pencil-outline</v-icon
                  >
                </v-btn>
                <v-btn icon>
                  <v-icon
                    @click="archive_supplier_product_time(item)"
                    color="grey lighten-1"
                    >mdi-delete-outline</v-icon
                  >
                </v-btn>
              </v-row>
            </v-list-item-action>
          </v-list-item>
          <v-card-title class="text-small">
            <small><strong>Check-in instructions</strong></small>
            <v-spacer />
          </v-card-title>
          <v-card-subtitle>
            Add instructions for travellers about what to bring etc
          </v-card-subtitle>
          <v-row>
            <v-col cols="12">
              <v-text-field label="Check-in Instructions" outlined dense>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="570px"
      overlay-color="#f4f4f5"
      overlay-opacity="1"
    >
      <v-btn fab @click="dialog = false" class="dialog-close">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialog_title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-menu
                  ref="menu1"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="check_in"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="check_in"
                      label="Check in time"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    v-model="check_in"
                    full-width
                    @click:minute="$refs.menu1.save(check_in)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-menu
                  ref="menu"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="check_out"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="check_out"
                      label="Check out time"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu3"
                    v-model="check_out"
                    full-width
                    @click:minute="$refs.menu.save(check_out)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="action-button">
            <v-btn color="success" fab @click="save_supplier_product_time()">
              <v-icon dark> mdi-check </v-icon>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: [],
  data: () => ({
    dialog_title: null,
    dialog: false,
    check_in: null,
    check_out: null,
    menu2: null,
    menu3: null,
  }),
  async mounted() {
    await this.$store.dispatch(
      "supplier/fetch_supplier_product_time",
      this.get_supplier_product_setup.id
    );
  },
  created() {},
  computed: {
    ...mapGetters({
      get_supplier_product_setup: "supplier/get_supplier_product_setup",
      get_supplier_product_time: "supplier/get_supplier_product_time",
    }),
  },
  methods: {
    create_time_checkein() {
      this.dialog_title = "Add time";
      this.dialog = true;
    },
    async save_supplier_product_time() {
      await this.$axios
        .post("suppliers/products/add_product_checkin", {
          check_in: this.check_in,
          check_out: this.check_out,
          supplier_prod_id: this.get_supplier_product_setup.id,
        })
        .then(({ data }) => {
          if (data.response) {
            this.check_in = null;
            this.check_out = null;
            this.$store.dispatch(
              "supplier/set_supplier_product_time",
              data.data
            );
            this.dialog = false;
          }
        });
    },
    async archive_supplier_product_time(e) {
      await this.$axios
        .patch(`suppliers/products/delete_product_time/${e.id}`, {
          check_in: e.check_in,
          check_out: e.check_out,
          supplier_prod_id: this.get_supplier_product_setup.id,
        })
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch(
              "supplier/set_supplier_product_time",
              data.data
            );
          }
        });
    },
  },
  watch: {},
};
</script>
<style scoped lang="scss">
.card {
  max-width: 100%;
  background-color: white;
  margin: 25px 15px;
  padding: 25px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}
.card .card-header {
  font-size: 24px;
  color: #343642;
  margin-bottom: 15px;
}
.card .card-header-items {
  text-align: center;
}
.card .card-header-items .header-items {
  margin: 0px 10px;
}
.media-instruction h1 {
  margin-bottom: 10px;
}
.media-instruction p {
  font-size: 11px;
}
.media-button {
  text-align: center;
}
.media-library-button {
  text-align: right;
}
.media-button .input-media {
  /* color: rgb(82, 90, 104); */
  display: none;
}
.media-library-button .input-media {
  /* color: rgb(82, 90, 104); */
  display: none;
}
.action-button {
  margin: 0px 17px 10px 17px;
}
.preview-image-container {
  position: relative;
}
.preview-image-container .preview-image {
  max-width: 240px;
}
.preview-image-container .preview-image-actions {
  display: flex;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  gap: 5px;
}
.preview-image-container:hover .preview-image {
  opacity: 0.3;
}
.preview-image-container:hover .preview-image-actions {
  opacity: 1;
}
.filtertabs-wrapper {
  max-width: 100%;
  margin-top: 18px;
}
.media-image:hover {
  cursor: pointer;
  box-shadow: 0px 1px 5px 5px rgba(169, 169, 169, 0.6);
}
.dialog-close {
  margin-left: -75px;
  position: absolute;
}
@media only screen and (max-width: 959px) {
  .card .card-header {
    text-align: center;
  }
  .card .card-header-items .header-items {
    margin: 5px 5px 10px 5px;
  }
}
</style>
